// Imports => React
import React, { memo, useMemo, useRef, useEffect, useState } from 'react';
import clsx from 'clsx';
import Blazy from 'blazy';

// Imports => Constants
import { TYPES } from '@constants';

// Imports => Utilities
import { AcUUID } from '@utils';

const _CLASSES = {
	MAIN: 'ac-image',
	WRP: {
		MAIN: 'ac-image-wrp',
		LOADED: 'ac-image-wrp--loaded',
		ERROR: 'ac-image-wrp--error',
	},
	BLAZY: 'b-lazy',
	IMAGE: 'ac-image--image',
	BACKGROUND: 'ac-image--background-image',
	ERROR: 'ac-image__error',
};

const AcImage = ({
	id = `ac-image-${AcUUID()}`,
	placeholder = 'data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==',
	source,
	srcset = false,
	type = TYPES.BACKGROUND,
	alt = '',
	size = {
		width: 'auto',
		height: 'auto',
	},
	callback,
	wrpClassName,
	className,
	lazy = true,
	placeholderCss = '',
	...rest
}) => {
	const [loaded, setLoaded] = useState(false);
	const [error, setError] = useState(false);

	const $wrp = useRef(null);
	const $element = useRef(null);

	let timer = null;
	let delay = 50;
	let blazy = null;

	useEffect(() => {
		init();
		return () => clearTimeout(timer);
	}, [source]);

	const init = () => {
		if (timer) clearTimeout(timer);
		if (!loaded && !error && source) {
			timer = setTimeout(() => {
				if (blazy === null) {
					blazy = new Blazy(getBlazyConfig);
				} else {
					blazy.revalidate();
				}
				delay = 10;
			}, delay);
		}
	};

	const getBlazyConfig = useMemo(() => {
		return {
			selector: `#${id}`,
			container: '#ac-scroller',
			offset: 250,
			loadInvisible: false,
			success: (elem) => {
				if ($element !== null && $element.current !== null) {
					setLoaded(true);
					if (callback) callback(true);
				}
			},
			error: (elem, msg) => {
				if ($element !== null && $element.current !== null) {
					const err =
						'The image source is either missing, broken or unsupported';
					setError(err);
					if (callback) callback(false);
				}
			},
		};
	}, [id, callback, source]);

	const getErrorClassNames = useMemo(() => {
		return clsx(_CLASSES.ERROR);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx([_CLASSES.BLAZY, _CLASSES.MAIN, className]);
	}, []);

	const getBackgroundStyleClassNames = useMemo(() => {
		return clsx([
			_CLASSES.BLAZY,
			_CLASSES.MAIN,
			_CLASSES.BACKGROUND,
			className,
		]);
	});

	const getWrapperClassNames = useMemo(() => {
		return clsx([
			_CLASSES.WRP.MAIN,
			wrpClassName,
			loaded && _CLASSES.WRP.LOADED,
			error && _CLASSES.WRP.ERROR,
			type === TYPES.IMAGE && _CLASSES.IMAGE,
		]);
	}, [error, loaded]);

	const renderError = useMemo(() => {
		if (!error) return null;

		return (
			<div
				className={getErrorClassNames}
				dangerouslySetInnerHTML={{
					__html: error,
				}}
			/>
		);
	}, [error]);

	const renderInlineImage = useMemo(() => {
		return (
			<img
				src={placeholder}
				data-src={source}
				className={getMainClassNames}
				alt={alt}
				ref={$element}
				width={size.width}
				height={size.height}
				id={id}
				{...rest}
			/>
		);
	}, [source]);

	const renderBackgroundImage = useMemo(() => {
		return (
			<>
				{placeholderCss && (
					<div
						style={{ ...placeholderCss, opacity: 1 }}
						className={getBackgroundStyleClassNames}
					></div>
				)}
				<div
					data-src={source}
					className={getBackgroundStyleClassNames}
					ref={$element}
					id={id}
					{...rest}
				/>
			</>
		);
	}, [source, placeholderCss]);

	const renderImage = useMemo(() => {
		if (type === TYPES.BACKGROUND) return renderBackgroundImage;
		else if (type === TYPES.IMAGE) return renderInlineImage;
	}, [type, renderInlineImage, renderBackgroundImage]);

	return (
		<div className={getWrapperClassNames} ref={$wrp}>
			{renderImage}
			{renderError}
		</div>
	);
};

export default memo(AcImage);
