import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  memo,
  useImperativeHandle,
  forwardRef,
} from 'react';
import clsx from 'clsx';

// Imports => Constants
import { DELAYS, ICONS, TYPES } from '@constants';

// Imports => Utilities
import { AcUUID, AcIsSet } from '@utils';

// Imports => Atoms
import AcTextInput from '@atoms/ac-text-input/ac-text-input.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
  MAIN: 'ac-search-input',
  INPUT: 'ac-search-input__field',
};

let _delay = null;

const AcSearchInput = forwardRef(
  (
    {
      id = AcUUID(),
      type = TYPES.TEXT,
      autofocus = false,
      initialValue = '',
      value = '',
      name = 'query',
      placeholder = 'Search...',
      disabled = false,
      callback,
      validation,
    },
    ref
  ) => {
    const $form = useRef(null);

    const handleSubmit = (event) => {
      if (event && event.persist) event.persist();
      if (event && event.stopPropagation) event.stopPropagation();
      if (event && event.preventDefault) event.preventDefault();
    };

    const handleCallback = (event, name, value) => {
      if (_delay) clearTimeout(_delay);

      _delay = setTimeout(() => {
        if (AcIsSet(value)) {
          if (callback) callback(event, name, value.trim());
        }
      }, DELAYS.SEARCH || 350);
    };

    const getSearchInputClassNames = useMemo(() => {
      return clsx(_CLASSES.INPUT);
    }, []);

    const getMainClassNames = useMemo(() => {
      return clsx(_CLASSES.MAIN);
    }, []);

    const getSearchInputOptions = useMemo(() => {
      const opts = {
        type,
        placeholder,
        name,
        value,
        initialValue,
        disabled,
        autocomplete: 'off',
        required: true,
        callback: handleCallback,
      };

      if (autofocus) {
        opts.focus = true;
      }

      return opts;
    }, [value, initialValue, disabled, autofocus]);

    useImperativeHandle(ref, () => ({
      resetValue: (reset = false) => {
        if ($form?.current?.reset) {
          $form?.current?.reset();

          if (callback && reset) callback(event, name, null);
        }
      },
    }));

    return (
      <div className={getMainClassNames}>
        <form
          autoComplete={'off'}
          onSubmit={handleSubmit}
          onReset={() => console.log('reset form!')}
          ref={$form}
        >
          <AcTextInput {...getSearchInputOptions} />
        </form>
      </div>
    );
  }
);

export default memo(AcSearchInput);
